import * as React from "react";
import { motion, Transition } from "framer-motion";
import { reverse } from "lodash";

const spinTransition = {
  loop: Infinity,
  duration: 1,
  ease: "linear",
};

const loadingContainerVariants = {
  start: {
    transition: {
      staggerChildren: 0.1,
    },
  },
  end: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};
const loadingCircleVariants = {
  start: {
    y: "0%",
  },
  end: {
    y: "100%",
  },
};

const loadingCircleTransition: Transition = {
  duration: 0.4,
  repeat: Infinity,
  repeatType: "reverse",
  ease: "easeInOut",
};

const Preloader: React.FC = () => {
  return (
    <div className="absolute z-50 flex flex-col items-center justify-center w-screen h-screen bg-white">
      <h1
        className="py-2 text-2xl font-semibold tracking-widest text-black font-poppins"
        style={{ wordSpacing: "-6px" }}
      >
        RADDAR
      </h1>
      <motion.div
        variants={loadingContainerVariants}
        className="relative flex justify-around w-12"
        initial="start"
        animate="end"
      >
        <motion.span
          className="box-border block w-1 h-1 border-4 border-black rounded-full"
          variants={loadingCircleVariants}
          transition={loadingCircleTransition}
        ></motion.span>
        <motion.span
          className="box-border block w-1 h-1 border-4 border-black rounded-full"
          variants={loadingCircleVariants}
          transition={loadingCircleTransition}
        ></motion.span>
        <motion.span
          className="box-border block w-1 h-1 border-4 border-black rounded-full"
          variants={loadingCircleVariants}
          transition={loadingCircleTransition}
        ></motion.span>
      </motion.div>
    </div>
  );
};

export default Preloader;
