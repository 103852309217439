import { useState, useEffect, RefObject } from "react"

export const useOnLoadImages = (ref: RefObject<HTMLElement>) => {
  const [status, setStatus] = useState(false)

  useEffect(() => {
    let interval: any = null
    const updateStatus = (images: HTMLImageElement[]) => {
      setStatus(
        images.map(image => image.complete).every(item => item === true)
      )
      if (!status && !interval)
        interval = setInterval(() => {
          updateStatus(images)
        }, 300)
    }

    if (!ref?.current) return
    setTimeout(() => {
      const imagesLoaded: HTMLImageElement[] = Array.from(
        ref.current!.querySelectorAll("img")
      )

      if (imagesLoaded.length === 0) {
        setStatus(true)
        return
      }

      const addListeners = () =>
        imagesLoaded.forEach((image, i) => {
          image.addEventListener("load", () => updateStatus(imagesLoaded), {
            once: true,
          })
          image.addEventListener("error", () => updateStatus(imagesLoaded), {
            once: true,
          })
          if (image.src.length > 0 || image.ariaHidden == "true") {
            imagesLoaded.splice(i, 1)
            updateStatus(imagesLoaded)
          }
        })

      addListeners()
    }, 50)

    setTimeout(() => {
      setStatus(true)
      clearInterval(interval)
    }, 7000)

    return
  }, [ref])

  return status
}
