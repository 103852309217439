import React, { useEffect, useCallback, useRef } from "react"
import { Link } from "gatsby"
import clsx from "clsx"

interface SideDrawerProps {
  navLinks: [{ label: { text: string }; link: { url: string } }]
  open: boolean
  onOpen: () => void
  onClose: () => void
}

const SideDrawer: React.FC<SideDrawerProps> = ({
  open,
  onClose,
  onOpen,
  navLinks,
}) => {
  const ref = useRef(null)

  const clickListener = useCallback(
    (e: MouseEvent) => {
      if (!(ref.current! as any).contains(e.target)) {
        onClose()
      }
    },
    [ref.current]
  )

  useEffect(() => {
    // Attach the listeners on component mount.
    if (open) document.addEventListener("click", clickListener)
    // Detach the listeners on component unmount.
    return () => {
      document.removeEventListener("click", clickListener)
    }
  }, [open])

  return (
    <div
      ref={ref}
      className={clsx(
        "flex flex-col justify-center items-center transform top-0 right-0 w-1/2 bg-white absolute h-full overflow-auto ease-in-out transition-all duration-300 z-20",
        open ? "translate-x-0" : "translate-x-full"
      )}
    >
      {navLinks.map((navLink, index) => {
        return (
          <Link key={index} to={navLink.link.url} className="no-underline">
            <div className="flex-initial py-8 text-lg text-center text-black hover:font-semibold">
              {navLink.label.text}
            </div>
          </Link>
        )
      })}
    </div>
  )
}

export default SideDrawer
