// If you don't want to use TypeScript you can delete this file!
import React from "react"
import HamburgerButton from "./hamburger-button"
import { Link } from "gatsby"

interface HeaderProps {
  siteTitle: string
  sideDrawerOpen: boolean
  openSideDrawer: () => void
  closeSideDrawer: () => void
}

const Header: React.FC<HeaderProps> = ({
  siteTitle,
  openSideDrawer,
  closeSideDrawer,
  sideDrawerOpen,
}) => {
  return (
    <header className="fixed z-30 flex items-center justify-between w-full h-12 px-5 bg-white flex-inital">
      <Link to="/" className="no-underline">
        <h1
          className="py-2 text-xl tracking-widest text-black font-poppins hover:font-semibold"
          style={{ wordSpacing: "-6px" }}
        >
          {siteTitle}
        </h1>
      </Link>
      <div className="w-6 h-6 mx-2">
        <HamburgerButton
          sideDrawerOpen={sideDrawerOpen}
          openSideDrawer={openSideDrawer}
          closeSideDrawer={closeSideDrawer}
        />
      </div>
    </header>
  )
}

export default Header
