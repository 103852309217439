import React from "react"
import clsx from "clsx"

interface HamburgerProps {
  openSideDrawer: () => void
  closeSideDrawer: () => void
  sideDrawerOpen: boolean
}

const HamburgerButton: React.FC<HamburgerProps> = ({
  sideDrawerOpen,
  openSideDrawer,
  closeSideDrawer,
}) => {
  const onClick = () => {
    if (!sideDrawerOpen) {
      openSideDrawer()
    } else {
      closeSideDrawer()
    }
  }
  return (
    <div
      className={clsx("hamburger-btn", sideDrawerOpen && "open")}
      onClick={onClick}
    >
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
}

export default HamburgerButton
