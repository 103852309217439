import React, { useState, useRef } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import clsx from "clsx";

import ScrollTop from "./scrollTop";
import Header from "./header";
import SideDrawer from "./side-drawer";
import useVH from "react-viewport-height";

const Layout: React.FC = ({ children }) => {
  useVH();
  const data = useStaticQuery(graphql`
    query {
      prismicNavigation {
        data {
          site_title
          navigation_links {
            label {
              text
            }
            link {
              url
            }
          }
        }
      }
    }
  `);

  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const [showScroll, setShowScroll] = useState(false);
  const contentWindowRef = useRef<HTMLDivElement>(null);

  const checkScrollTop = () => {
    if (contentWindowRef.current) {
      if (!showScroll && contentWindowRef.current.scrollTop > 600) {
        setShowScroll(true);
      } else if (showScroll && contentWindowRef.current.scrollTop <= 600) {
        setShowScroll(false);
      }
    }
  };

  const scrollTop = () => {
    if (contentWindowRef.current)
      contentWindowRef.current.scrollTo({ top: 0, behavior: "smooth" });
  };

  const openSideDrawer = () => {
    setSideDrawerOpen(true);
  };

  const closeSideDrawer = () => {
    setSideDrawerOpen(false);
  };

  const { site_title, navigation_links } = data.prismicNavigation.data;
  return (
    <div className="relative inset-0 z-0 w-full font-poppins">
      <ScrollTop scrollTop={scrollTop} showScroll={showScroll} />
      <div
        className={clsx(
          "duration-500 ease-in-out bg-black fixed z-20 inset-0 transition",
          sideDrawerOpen ? "bg-opacity-0" : "bg-opacity-0 pointer-events-none"
        )}
      >
        <SideDrawer
          open={sideDrawerOpen}
          onOpen={openSideDrawer}
          onClose={closeSideDrawer}
          navLinks={navigation_links}
        />
      </div>
      <div className="flex flex-col w-full h-full min-h-screen">
        <Header
          siteTitle={site_title}
          sideDrawerOpen={sideDrawerOpen}
          openSideDrawer={openSideDrawer}
          closeSideDrawer={closeSideDrawer}
        />
        <div
          onScroll={checkScrollTop}
          ref={contentWindowRef}
          className="relative flex-auto w-full mt-12 overflow-auto content-wrapper"
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
