import React from "react";
import upArrow from "../images/up-arrow.png";

interface ScrollTopProps {
  scrollTop: () => void;
  showScroll: boolean;
}

const ScrollTop: React.FC<ScrollTopProps> = ({ scrollTop, showScroll }) => {
  return (
    <div
      className={`scroll-top ${
        showScroll ? "inline-block" : "hidden"
      } xl:hidden`}
      onClick={scrollTop}
    >
      <img src={upArrow} />
    </div>
  );
};

export default ScrollTop;
